@import url(https://fonts.googleapis.com/css2?family=Unica+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300&family=Lato:ital,wght@0,400;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 background-color: #cecbcb;
 color: #3a3a39;
 height: 100%;
 overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
  padding: 0;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 200px;
    background-color: #3a3a39;
    margin-top: 20px;
    padding: 20px 0;
} 

.logo{
    width: 250px;
    /* height: 350px; */
    /* margin-top: -115px; */
    }
.card{
  width: 550px;
  height: 350px;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  margin-bottom: 16px;
  margin: 0 20px 50px 20px;
  overflow: hidden;
  cursor: pointer;
}

.cardHeading{
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.imageContainer {
  width: 550px;
  height: 450px;
  box-shadow: inset 3px 3px 3px #dadada;
}

.image {
  border-radius: 6px;
  object-fit: contain;
  width: 550px;
  height: auto;
  box-shadow: inset 3px 3px 3px #dadada;
}



.page {
  padding: 20px 0px;
  font-family: 'Inria Sans', sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: column;
          justify-content: column;
  -webkit-align-items: center;
          align-items: center;
  color: #3a3a39;
}

.heading {
  color: #3a3a39;
  margin: 15px 0;
  text-decoration: underline;
  font-weight: 700;
}

.subText {
  font-size: 20px;
  background: linear-gradient(to right, #d61c1c, #F91817, #d61c1c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* For webkit browsers */
  background-clip: text;
  text-fill-color: transparent;
  /* Standard */

}

.bodyText {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  max-width: 1050px;
  font-size: 22px;
}

.text {
  margin-bottom: 16px;
}

.services {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-image: linear-gradient(#3f3f3f, #cecbcb);
  justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-top: 10px;
}

.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%;
}

.button {
  background-color: #243049;
  padding: 3px 20px;
  border-radius: 8px;
  box-shadow: 3px 5px 3px #969696aa;
  color: white;
}

.center {
  text-align: center;
}

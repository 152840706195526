.header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 200px;
    background-color: #3a3a39;
    margin-top: 20px;
    padding: 20px 0;
} 

.logo{
    width: 250px;
    /* height: 350px; */
    /* margin-top: -115px; */
    }
.card{
  width: 550px;
  height: 350px;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  margin-bottom: 16px;
  margin: 0 20px 50px 20px;
  overflow: hidden;
  cursor: pointer;
}

.cardHeading{
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.imageContainer {
  width: 550px;
  height: 450px;
  box-shadow: inset 3px 3px 3px #dadada;
}

.image {
  border-radius: 6px;
  object-fit: contain;
  width: 550px;
  height: auto;
  box-shadow: inset 3px 3px 3px #dadada;
}



@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300&family=Lato:ital,wght@0,400;1,300&display=swap');

.page {
  padding: 20px 0px;
  font-family: 'Inria Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  display: flex;
  justify-content: column;
  align-items: center;
  color: #3a3a39;
}

.heading {
  color: #3a3a39;
  margin: 15px 0;
  text-decoration: underline;
  font-weight: 700;
}

.subText {
  font-size: 20px;
  background: linear-gradient(to right, #d61c1c, #F91817, #d61c1c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* For webkit browsers */
  background-clip: text;
  text-fill-color: transparent;
  /* Standard */

}

.bodyText {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1050px;
  font-size: 22px;
}

.text {
  margin-bottom: 16px;
}

.services {
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(#3f3f3f, #cecbcb);
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 10px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.button {
  background-color: #243049;
  padding: 3px 20px;
  border-radius: 8px;
  box-shadow: 3px 5px 3px #969696aa;
  color: white;
}

.center {
  text-align: center;
}